
import LargeButton from "@/components/LargeButton.vue";
import TextField from "@/components/TextField.vue";
import { defineComponent } from "vue";
import StadiumBackground from "@/components/StadiumBackground.vue";
import StadiumComponent from "@/components/StadiumComponent.vue";
import { signUp } from "@/lib/aws-cognito";
import { mapActions } from "vuex";
export default defineComponent({
  components: {
    StadiumBackground,
    LargeButton,
    TextField,
    StadiumComponent,
  },
  name: "Home",
  data() {
    return {
      loading: false,
      step: "signin",
      loginErrorMessage: "",
      signupErrorMessage: "",
      showBackground: null as unknown as boolean,
      email: "",
      phone: "",
      password: "",
      acceptedTerms: false,
      confirmPassword: "",
      mobile: false,
      confirmMailing: false,
      confirmingSMS: false,
      name: "",
      code: "",
      resetConfirmPassMsg: "",
      loginResponse: null,
      errors: {
        email: "",
        name: "",
        phone: "",
        password: "",
        confirmPassword: "",
        confirmMailing: "",
      },
      resetResponse: 0,
      resetConfirmResponse: 0,
      resetResponseMsg: "",
      voteModal: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },

  created() {
    if (localStorage.getItem("act")) {
      this.acceptedTerms = true;
    } else {
      false;
    }
  },

  watch: {
    confirmMailing() {
      if (this.confirmMailing) {
        this.signupErrorMessage = "";
      }
    },

    loginResponse() {
      if (this.loginResponse === 200) {
        if (window.innerWidth <= 550) {
          window.location.replace(
            `https://tour.neoquimicaarenavirtual.com.br/mobile/?etk=${localStorage.getItem(
              "userSub"
            )}`
          );
        } else {
          window.location.replace(
            `https://tour.neoquimicaarenavirtual.com.br?etk=${localStorage.getItem(
              "userSub"
            )}`
          );
        }
      }
      if (this.loginResponse === 404) {
        this.loginErrorMessage = "Email ou senha incorretos";
        this.loading = false;
      }
      if (this.loginResponse === 409) {
        this.loginErrorMessage = "Número de tentativas excedida";
        this.loading = false;
      }
      if (this.loginResponse === 406) {
        this.loginErrorMessage = "Complete seu cadastro";
        this.loading = false;
        this.step = "confirmation";
      }
      if (this.loginResponse === 500) {
        this.loginErrorMessage =
          "Houve uma falha de comunicação. Tente novamente ";
        this.loading = false;
      }
      this.loading = false;
    },

    resetResponse() {
      if (this.resetResponse !== 200) {
        this.resetResponseMsg = "Houve algum erro. Tente novamente";
      } else {
        this.step = "recoveryCode";
      }
      this.loading = false;
    },

    resetConfirmResponse() {
      if (this.resetConfirmResponse !== 200) {
        this.resetConfirmPassMsg = "Houve algum erro. Tente novamente";
      } else {
        this.startSignIn();
      }
      this.loading = false;
    },
  },

  methods: {
    ...mapActions("auth", ["signUp"]),
    ...mapActions("auth", ["login"]),
    ...mapActions("auth", ["vote"]),
    ...mapActions("auth", ["completeSignup"]),
    ...mapActions("auth", ["resetPass"]),
    ...mapActions("auth", ["resetConfirmPass"]),
    startSignUp() {
      this.step = "signup";
      this.email = "";
      this.password = "";
    },

    acceptCookies() {
      localStorage.setItem("act", "true");
      this.acceptedTerms = true;
    },

    startForgetPassword() {
      this.step = "forgetpass";
      this.email = "";
      this.password = "";
    },

    async tryToFindMail(event: any) {
      this.loading = true;
      await this.resetPass({ username: this.email, vue: this });
      this.loading = false;
    },

    async changePassword(event: any) {
      event.preventDefault();
      this.loading = true;
      await this.resetConfirmPass({
        username: this.email,
        verificationCode: this.code,
        newPassword: this.password,
        vue: this,
      });
      this.loading = false;
    },

    async doLogin() {
      this.loading = true;
      this.loginErrorMessage = "";
      await this.login({
        email: this.email,
        password: this.password,
        vue: this,
      });
      this.loading = false;
    },

    startSignIn() {
      this.step = "signin";
    },

    // async doCompleteSignup () {
    //   this.validatePhone()
    //   this.validateName()
    //   const response = await this.completeSignup({
    //     token: localStorage.getItem('userSub'),
    //     phone: this.phone,
    //     name: this.name,
    //     vue: this
    //   });
    //   if (response.status === 200) {
    //     window.location.href = `https://tour.neoquimicaarenavirtual.com.br?etk=${localStorage.getItem('cliId')}`;
    //   }
    // },

    async submitSignUp() {
      if (this.confirmMailing !== true) {
        this.signupErrorMessage =
          "Você precisa concordar com os termos de privacidade";
        return;
      }
      const isValid = this.validateForm();
      if (!isValid) {
        return;
      }

      this.loading = true;
      // get only string integers
      let phone;
      if (!this.phone || this.phone.trim().length === 0) {
        phone = "21999999999";
      } else {
        phone = this.phone.replace(/\D/g, "");
      }
      const response = await this.signUp({
        email: this.email,
        password: this.password,
        phone: "+55" + phone,
        name: this.name,
      });
      if (!response) {
        await this.vote({
          UserId: this.email,
          Name: this.name,
          accepted: this.confirmMailing,
          acceptedSMS: this.confirmingSMS,
          Phone: "+55" + phone,
        });
        this.loading = false;
        this.step = "confirmation";
        return;
      }
      if (response === "An account with the given email already exists.") {
        this.signupErrorMessage = "Este email já está cadastrado";
      } else {
        this.signupErrorMessage = "Ocorreu um erro. Tente novamente";
      }
      this.loading = false;
    },
    async tryLocateEmail() {
      if (this.email === "andrecanutoc@gmail.com") {
        this.step = "insertPass";
        return;
      }
      this.step = "signup";
    },

    async tryRegister() {
      this.step = "confirmation";
    },

    backToLanding() {
      this.step = "landing";
      this.email = "";
      this.phone = "";
      this.password = "";
      this.confirmPassword = "";
      this.errors = {
        email: "",
        phone: "",
        name: "",
        password: "",
        confirmPassword: "",
        confirmMailing: "",
      };
    },

    validateEmail() {
      if (!this.email) {
        return (this.errors.email = "Preenchimento Obrigatório");
      }

      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValidEmail = re.test(String(this.email).toLowerCase());

      this.errors.email = isValidEmail ? "" : "E-mail inválido";
    },

    validatePhone() {
      // if (!this.phone) {
      //   return (this.errors.phone = "Preenchimento Obrigatório");
      // }
      if (this.phone && this.phone.trim().length > 0) {
        const re = /^[1-9]{2} [2-9][0-9]{4} [0-9]{4}$/;
        const isValidPhone = re.test(String(this.phone));

        this.errors.phone = isValidPhone ? "" : "Telefone inválido";
      } else {
        this.errors.phone = "";
      }
    },

    validateName() {
      if (!this.name || this.name.trim().length === 0) {
        return (this.errors.name = "Preenchimento Obrigatório");
      }
      this.errors.name = "";
    },

    validatePassword() {
      if (!this.password) {
        return (this.errors.password = "Preenchimento Obrigatório");
      }

      if (this.password.length <= 7) {
        return (this.errors.password = "Senha deve ter mais de 7 caracteress");
      }

      // const reLowerCase = /^(?=.*[a-z])/;
      // if (!reLowerCase.test(this.password)) {
      //   return (this.errors.password =
      //     "Senha deve conter pelo menos uma letra minúscula");
      // }

      // const reUpperCase = /^(?=.*[A-Z])/;
      // if (!reUpperCase.test(this.password)) {
      //   return (this.errors.password =
      //     "Senha deve conter pelo menos uma letra maiúscula");
      // }

      // const reNumbers = /^(?=.*\d)/;
      // if (!reNumbers.test(this.password)) {
      //   return (this.errors.password =
      //     "Senha deve conter pelo menos um número");
      // }

      // const reSpecialChars = /^(?=.*[@$!%*?&])/;
      // if (!reSpecialChars.test(this.password)) {
      //   return (this.errors.password =
      //     "Senha deve conter pelo menos um caractere especial");
      // }

      this.errors.password = "";
    },

    validateConfirmPassword() {
      if (!this.confirmPassword) {
        return (this.errors.confirmPassword = "Preenchimento Obrigatório");
      }

      const isValidConfirmPassword = this.confirmPassword === this.password;

      this.errors.confirmPassword = isValidConfirmPassword
        ? ""
        : "Senhas não são iguais";
    },

    validateForm() {
      this.validateEmail();
      // this.validatePhone();
      this.validatePassword();
      // this.validateConfirmPassword();
      return Object.values(this.errors).every((error) => error === "");
    },

    onWindowResize() {
      this.showBackground = window.innerWidth >= 768;
      if (window.innerWidth <= 768) {
        this.mobile = true;
      }
    },
  },
  mounted() {
    const alredyDoneSignup = false;
    if (alredyDoneSignup) {
      this.step = "signupContinue";
    }
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onWindowResize);
  },
});
